import React, { useEffect } from 'react';
import "../Assets/styles/Blog.css"
import BlogImage from "../Assets/Resources/blogimage.jpeg"
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import AllPageLoader from '../Components/AllPageLoader';
import NewBlogImage from "../Assets/Resources/blog-new.webp"
import AOS from "aos";
import "aos/dist/aos.css";

const Blog = () => {


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);


    return (
        <>
            <AllPageLoader />
            <section className='breadcrumb'>
                <div className="container">
                    <div className="row">
                        <div className="breadcrumb-title">
                            <h1 className='ban-title-animation'>Blog</h1>
                        </div>
                        <div className="breadcrumb-title-outline">
                            <h1 className='ban-title-animation'>Blog</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className='blog-section'>
                <div className="container">
                    {/* <div className="sec-title">
                        <h1>Latest Thoughts</h1>
                    </div> */}
                    <div className="row">
                        <div className="blog-large">
                            <a href="/blog-overview">
                                <div className="blog-image" data-aos="zoom-in" data-aos-duration="2000">
                                    <img src={NewBlogImage} alt="" />
                                    <div className="date" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="500">
                                        <h6>Oct 20, 2024</h6>
                                    </div>
                                </div>
                            </a>
                            <div className="blog-content">
                                <div className="blog-title">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <a href="/blog-overview">
                                                <h1 className='mini-title-animation'>SOC 2 TYPE 2 CERTIFIED</h1>
                                            </a>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="arrow-shape-right read-more" data-aos="fade-left" data-aos-duration="2000">
                                                <a href="/blog-overview"><h6>READ MORE</h6></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="sec-title">
                            <h1 data-aos="fade-down" data-aos-duration="2000">Latest Blogs</h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="600">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="900">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1200">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1500">
                                <a href="/blog-overview">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="blog-content">
                                    <div className="blog-title">
                                        <a href="/blog-overview">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </a>
                                    </div>
                                    <div className="read-more">
                                        <a href="/blog-overview"><h6>READ MORE</h6></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >





        </>
    )
}

export default Blog