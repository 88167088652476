import React, { useEffect } from 'react';
import "../Assets/styles/Blog.css"
import BlogImage from "../Assets/Resources/blogimage.jpeg"
import { Link } from 'react-router-dom'
import Instagram from "../Assets/Resources/insta.svg"
import Facebook from "../Assets/Resources/fb.svg"
import Linkedin from "../Assets/Resources/linkedin.svg"
import Whatsapp from "../Assets/Resources/whatsapp.svg"
import Twitter from "../Assets/Resources/x.svg"
import AllPageLoader from '../Components/AllPageLoader';
import NewBlogImage from "../Assets/Resources/blog-new.webp"

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import AOS from "aos";
import "aos/dist/aos.css";



const BlogOverview = () => {




    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);




    return (
        <>
            <AllPageLoader />
            <section className='blog-section blog-overview-page'>
                <div className="container">
                    {/* <div className="sec-title">
                        <h1>Latest Thoughts</h1>
                    </div> */}
                    <div className="row">
                        <div className="blog-large">
                            <div className="blog-image" data-aos="zoom-in" data-aos-duration="2000">
                                <img src={NewBlogImage} alt="" />
                                <div className="date" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="500">
                                    <h6>Oct 20, 2024</h6>
                                </div>
                                <a href="/blog">
                                    <div className="arrow-shape read-more" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="500">
                                        <h6>BACK</h6>
                                    </div>
                                </a>
                            </div>
                            <div className="blog-content">
                                <div className="blog-title">
                                    <div className="row">
                                        <div className="col-lg-10">
                                            <h1 className='mini-title-animation'>SOC 2 TYPE 2 CERTIFIED</h1>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="share" data-aos="fade-left" data-aos-duration="2000" >
                                                <button data-bs-toggle="modal" data-bs-target="#staticBackdrop">Share</button>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className="blog-overview-content">
                            <p className='para-animation'>Exciting news from Prophecy Technologies! We are now proudly SOC 2 (Type 2) compliant. This is truly a remarkable achievement that underscores our unwavering commitment to data security. </p>
                            <p className='para-animation'>In an era where digital privacy and security are of utmost importance, SOC 2 compliance is a testament to our dedication to safeguarding sensitive information. Our recent audit report with zero exceptions speaks volumes about the effectiveness of our controls and the meticulousness of our policies and procedures. </p>
                            <p className='para-animation'>SOC 2 Type 2 audits are rigorous examinations that assess a company's adherence to the Trust Service Principles, ensuring that our organization meets the highest standards of data security and integrity. By obtaining this accreditation, Prophecy Technologies has demonstrated its capability to handle the critical data of clients with the utmost care and responsibility. </p>
                            <p className='para-animation'>This accomplishment is a reflection of the hard work, dedication, and expertise of the entire Prophecy Technologies team. Our collective effort has paved the way for this significant milestone, and your commitment to excellence in data security is commendable.</p>
                            <p className='para-animation'>As we continue to uphold these standards and prioritize the safety of our clients' information, we reinforce trust and confidence in our services. </p>
                            <p className='para-animation'>Congratulations once again on this remarkable achievement for our team! We will continue success and prosperity for Prophecy Technologies in the future!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="sec-title">
                            <h1 data-aos="fade-down" data-aos-duration="2000">Latest Blogs</h1>
                        </div>
                        <div className="col-lg-4">
                            <a href="/blog-overview">
                                <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-title">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </div>
                                        <div className="read-more">
                                            <Link to="/blog-overview"><h6>READ MORE</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4">
                            <a href="/blog-overview">
                                <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-title">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </div>
                                        <div className="read-more">
                                            <Link to="/blog-overview"><h6>READ MORE</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4">
                            <a href="/blog-overview">
                                <div className="blog-item" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="600">
                                    <div className="blog-image">
                                        <img src={BlogImage} alt="" />
                                        <div className="date">
                                            <h6>Oct 20, 2024</h6>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-title">
                                            <h1>Lorem ipsum dolor sit amet consectetur.</h1>
                                        </div>
                                        <div className="read-more">
                                            <Link to="/blog-overview"><h6>READ MORE</h6></Link>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section >



            {/* share modal popup */}


            <div
                className="modal fade "
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered  modal-dialog-scrollable career-modal share-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Share via
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="social-media-icons">
                                <div className="icon">
                                    <img src={Instagram} alt="" />
                                </div>
                                <div className="icon">
                                    <img src={Facebook} alt="" />
                                </div>
                                <div className="icon">
                                    <img src={Whatsapp} alt="" />
                                </div>
                                <div className="icon">
                                    <img src={Twitter} alt="" />
                                </div>
                                <div className="icon">
                                    <img src={Linkedin} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >


        </>
    )
}

export default BlogOverview