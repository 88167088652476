
import React, { useEffect } from 'react';
import "../Assets/styles/Services.css"
import ServiceBanShape from "../Assets/Resources/service-ban-shape.png"
import Serviceoneimg from "../Assets/Resources/service-one-1.png"
import RightArrow from "../Assets/Resources/service-right-arrow.svg"
import KeyBenefitsImg from "../Assets/Resources/adobe-benefits-img.png"
import KeyBenefitsImg2 from "../Assets/Resources/adobe-benefits-img-2.png"
import KeyBenefitsImg3 from "../Assets/Resources/adobe-benefits-img-3.png"
import KeyBenefitsImg4 from "../Assets/Resources/adobe-benefits-img-4.png"
import KeyBenefitsImg5 from "../Assets/Resources/adobe-benefits-img-5.png"
import BenefitsIcon1 from "../Assets/Resources/benefits-icon-1.png"
import AiDataImage from "../Assets/Resources/ai-data-img.png"
import Logo from "../Assets/Resources/banner-logo.svg";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import AOS from "aos";
import "aos/dist/aos.css";

const AiandDataAnalytics = () => {



    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);



    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);







    return (
        <>
            <section className='service-hero-section'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="banner-content">
                            <h1 className='ban-title-animation'>Prophecy <br />Technologies!</h1>
                            <h6 data-aos="fade-down" data-aos-duration="2000" data-aos-delay="500">Unlocking Future Potential with Data and AI</h6>
                            <p data-aos="fade-down" data-aos-duration="2000" data-aos-delay="1000">Talk with Our Experts</p>
                        </div>
                    </div>
                </div>
                <div className="service-hero-shape">
                    <img src={ServiceBanShape} alt="" />
                </div>
            </section>


            <section className='service-section-one  sec-padding smarteerTomorrow'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="content large-title">
                                <h6 data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300">Prophecy Technologies</h6>
                                <h1 className='mini-title-animation'>AI & Data <br />Analytics for <br />a Smarter <br />Tomorrow</h1>
                                <p className='para-animation'>Prophecy Technologies leverages the power of AI and precision-driven data
                                    analytics to shape smarter, data-informed decisions. With advanced algorithms
                                    and tailored insights, our solutions unlock hidden opportunities within your data,
                                    transforming it into actionable intelligence. Designed to optimize and future-proof
                                    your strategies, our AI-driven analytics bring clarity to complex data, ensuring
                                    you stay ahead in a rapidly evolving digital landscape.</p>
                                <p className='para-animation'>Through innovative AI models and meticulous data analysis, we bridge the gap
                                    between raw data and meaningful intelligence. Prophecy Technologies is
                                    committed to harnessing the future of AI, helping you make the right decisions
                                    today for a smarter tomorrow.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="image" data-aos="fade-left" data-aos-duration="2000" data-aos-delay="300">
                                <img src={AiDataImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='service-section-one service-section-two service-points-stick sec-padding' >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="content sticky-content large-title">
                                <h1 className='mini-title-animation'>Unlock <br />Business <br />Potential</h1>
                                <h6 data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300">With Our Comprehensive <br />Services</h6>
                                <p className='para-animation'>At Prophecy Technologies, we offer a range of services designed to support your business journey. Our seven key services include:</p>
                                <p className='para-animation'>By partnering with us, you gain access to expertise and resources that drive innovation and operational excellence. Our team is committed to delivering tailored solutions that align with your business objectives.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-list-items" >
                                <div className="item active" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Adobe Solutions</h1>
                                        <p>Digital marketing and creative tools for enhanced engagement.</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Application Modernization</h1>
                                        <p>Revamping legacy systems for improved efficiency.</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Cybersecurity</h1>
                                        <p>Comprehensive solutions to safeguard your digital assets.</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Digital Transformation</h1>
                                        <p>IT services that enhance product experiences.</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Infrastructure Modernization</h1>
                                        <p>Upgrading your infrastructure for greater agility.</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>SAP Solutions</h1>
                                        <p>Business excellence through comprehensive analytics and asset management</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                                <div className="item" data-aos="fade-left" data-aos-duration="2000" >
                                    <div className="item-content">
                                        <h1>Service Now</h1>
                                        <p>Cloud-based platform automating IT service management</p>
                                    </div>
                                    <div className="icon">
                                        <img src={RightArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="checked-shape">
                <img src={CheckedShape} alt="" />
            </div> */}
            </section>



            <div className="gradient-bg">
                <section className='service-section-three gradient-bg sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="title">
                                    <h1 className='mini-title-animation'>Elevate Your <br />Digital <br />Marketing <br />with Adobe</h1>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="image" data-aos="fade-left" data-aos-duration="2000" >
                                    <img src={KeyBenefitsImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <p className='para-animation'>Adobe is at the forefront of digital marketing and creative software. Its wide range of tools and applications enables businesses to create visually stunning content that captures audience attention. Whether you’re designing websites, editing photos, or producing videos, Adobe’s software suite offers the flexibility and power needed to bring your creative visions to life. From graphic design to video editing, Adobe tools can help businesses enhance their online presence and marketing campaigns, ultimately leading to increased engagement and conversions.</p>
                        <div className="benefit-items">
                            <div className="title">
                                <h6 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">Key Benefits of Adobe Solutions:</h6>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" >
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Creativity Unleashed</h6>
                                            <p>Harness the full potential of design and multimedia tools</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>User-Friendly Interfaces</h6>
                                            <p>Easy to use for both beginners and professionals</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Comprehensive Support</h6>
                                            <p>Access tutorials and resources to enhance skillsets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gradient-bg-radial-1"></div>
                    <div className="gradient-bg-radial-2"></div>
                </section>
            </div>


            <section className='service-section-three no-gradient-bg sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="image" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="500">
                                <img src={KeyBenefitsImg2} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="title">
                                <h6 data-aos="fade-left" data-aos-duration="2000" data-aos-delay="500">Transform <br />Your Business with</h6>
                                <h1 className='mini-title-animation'>Application <br />Modernization</h1>
                            </div>
                        </div>
                    </div>
                    <p className='para-animation'>In today’s digital landscape, businesses must adapt to stay relevant. Application modernization is essential for organizations looking to enhance their operational efficiency. By updating legacy systems and implementing modern applications, businesses can unlock new capabilities and improve user experiences. This transformation helps streamline processes, reduce costs, and foster innovation.</p>
                    <div className="benefit-items">
                        <div className="title">
                            <h6 data-aos="fade-up" data-aos-duration="2000" >What to Expect from Application Modernization</h6>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Enhance Performance</h6>
                                        <p>Improved speed and efficiency of applications.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Cost Saving</h6>
                                        <p>Reduced maintenance costs of outdated systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Scalability</h6>
                                        <p>Solutions that grow with your business needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className="gradient-bg">
                <section className='service-section-three gradient-bg sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="title">
                                    <h1 className='mini-title-animation'>Safeguard <br />Your Future <br />with <br />Cybersecurity</h1>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="image" data-aos="fade-left" data-aos-duration="2000" >
                                    <img src={KeyBenefitsImg3} alt="" />
                                </div>
                            </div>
                        </div>
                        <p className='para-animation'>As cyber threats become increasingly sophisticated, robust cybersecurity measures are more critical than ever. Organizations must prioritize protecting sensitive data and maintaining customer trust. Comprehensive cybersecurity solutions involve developing documentation, creating policies, and conducting training to ensure compliance and preparedness.</p>
                        <div className="benefit-items">
                            <div className="title">
                                <h6 data-aos="fade-up" data-aos-duration="2000" >Benefits of Investing in Cybersecurity:</h6>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" >
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Risk Mitigation</h6>
                                            <p>Protect against data breaches and cyber threats.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Regulatory Compliance</h6>
                                            <p>Stay compliant with industry standards and regulations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Peace of Mind</h6>
                                            <p>Focus on your business while we manage your security.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gradient-bg-radial-1"></div>
                    <div className="gradient-bg-radial-2"></div>
                </section>
            </div>


            <section className='service-section-three no-gradient-bg sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="image" data-aos="fade-right" data-aos-duration="2000" >
                                <img src={KeyBenefitsImg4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="title">
                                <h1 className='mini-title-animation'>Embrace <br />Change with <br />Digital <br />Transformation</h1>
                            </div>
                        </div>
                    </div>
                    <p className='para-animation'>Digital transformation is not just a trend; it’s a necessity for organizations aiming to thrive in a competitive environment. Companies are increasingly adopting IT transformation services to meet shifting consumer preferences and demands for enhanced product experiences. By leveraging technology, organizations can improve operational efficiency, enhance customer engagement, and drive innovation.</p>
                    <div className="benefit-items">
                        <div className="title">
                            <h6 data-aos="fade-up" data-aos-duration="2000">Key Aspects of Digital Transformation</h6>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Agility</h6>
                                        <p>Improved speed and efficiency of applications.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Enhanced Customer Experiences:</h6>
                                        <p>Deliver personalized and engaging interactions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={BenefitsIcon1} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6>Data-Driven Insights:</h6>
                                        <p>Utilize analytics to inform business strategies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className="gradient-bg">
                <section className='service-section-three gradient-bg sec-padding'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="title">
                                    <h1 className='mini-title-animation'>Modernize <br />Your <br />Infrastructure <br />for Growth</h1>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="image" data-aos="fade-left" data-aos-duration="2000">
                                    <img src={KeyBenefitsImg5} alt="" />
                                </div>
                            </div>
                        </div>
                        <p className='para-animation'>Your organizational infrastructure forms the backbone of your operations. Elevating this infrastructure is crucial for maintaining a competitive advantage. Infrastructure modernization involves rehosting, re-platforming, and optimizing existing systems to create a more agile and responsive framework. This ensures that your organization can effectively meet the demands of the market while minimizing downtime.</p>
                        <div className="benefit-items">
                            <div className="title">
                                <h6 data-aos="fade-up" data-aos-duration="2000">Benefits of Infrastructure Modernization:</h6>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Increased Efficiency</h6>
                                            <p>Streamline operations and reduce costs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Scalability</h6>
                                            <p>Easily adapt to growth and changes in demand.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="item" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                        <div className="icon">
                                            <img src={BenefitsIcon1} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6>Improved Performance</h6>
                                            <p>Enhanced speed and reliability of services</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gradient-bg-radial-1"></div>
                    <div className="gradient-bg-radial-2"></div>
                </section>
            </div>

            <section className='service-cta sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="image" data-aos="flip-right" data-aos-duration="3000" >
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="content">
                                <h1 className='mini-title-animation'>Why Choose <br />Prophecy <br />Technologies ?</h1>
                                <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="300">In an era where technology is pivotal to success, Prophecy Technologies stands out as a reliable partner for businesses looking to thrive. Our commitment to innovation, client satisfaction, and excellence in service delivery positions us as leaders in the industry. With a wealth of experience and a dedication to understanding your unique needs, we provide solutions that foster growth and resilience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='cta-section common-btn sec-padding'>
                <div className="container">
                    <div className="row">
                        <div className="sec-title">
                            <div className="large-title">
                                <h1 className='mini-title-animation'>Let's Build Your Future Together</h1>
                            </div>
                            <p data-aos="fade-up" data-aos-duration="2000">At Prophecy Technologies, we believe that every business has the potential to achieve greatness. Our mission is to help you unlock that potential by providing the tools, technologies, and strategies needed to succeed in today’s competitive landscape. If you're ready to take the next step in your business’s digital transformation, contact us today and discover how Prophecy Technologies can help you achieve your goals.</p>
                            <button data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">Get Started</button>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default AiandDataAnalytics