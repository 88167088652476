import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import Blog from "./Pages/Blog";
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import BlogOverview from './Pages/BlogOverview';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Teams from './Pages/Teams';
import Career from './Pages/Career';
import CookiePolicy from './Pages/CookiePolicy';
import AiandDataAnalytics from './Pages/AiandDataAnalytics';
import DigitalAcceleration from './Pages/DigitalAcceleration';
import ApplicationModernization from './Pages/ApplicationModernization';
import DatabaseModernization from './Pages/DatabaseModernization';
import InfraStructureModernization from './Pages/InfraStructureModernization';
import CyberSecurity from './Pages/CyberSecurity';
import SiteReliabilityServices from './Pages/SiteReliabilityServices';
import Automation from './Pages/Automation';
import StaffingandITConsulting from './Pages/StaffingandITConsulting';
import RoboticProcessAutomation from './Pages/RoboticProcessAutomation';
import DataandAI from './Pages/DataandAI';
import CloudandDevOps from './Pages/CloudandDevOps';
import SnowflakeandInformatica from './Pages/SnowflakeandInformatica';
import ServiceNow from './Pages/ServiceNow';
import EPR from './Pages/EPR';
import CRM from './Pages/CRM';
import AdoveExperienceManager from './Pages/AdoveExperienceManager';
import Optimizely from './Pages/Optimizely';
import About from './Pages/About';

import { useEffect, useRef } from 'react'
import Lenis from '@studio-freight/lenis';
import AnimationCursor from './Components/AnimationCursor';
import { isMobile } from 'react-device-detect';

function App() {
  const lenisRef = useRef(null)

  useEffect(() => {
    // Initialize Lenis instance
    const lenis = new Lenis({
      smooth: true, // Enable smooth scrolling
      lerp: 0.02, // Easing function for smoothness
      direction: 'vertical', // Scroll direction
      touchMultiplier: isMobile ? 2.0 : 1.5,
    });

    // RequestAnimationFrame to handle smooth scrolling
    const update = (time) => {
      lenis.raf(time);
      requestAnimationFrame(update); // Recurse the animation frame
    }

    // Start the smooth scroll animation
    requestAnimationFrame(update);

    // Cleanup function
    return () => {
      // No need to manually update, Lenis will handle it
    };
  }, []);


  return (
    <BrowserRouter>
      <div className="prophecy">
        
        <AnimationCursor className="CursorAnimation"/>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog-overview" element={<BlogOverview />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="/team" element={<Teams />} />
          <Route path="/career" element={<Career />} />
          <Route path="/ai-and-data-analytics" element={<AiandDataAnalytics />} />
          <Route path="/digital-acceleration" element={<DigitalAcceleration />} />
          <Route path="/application-modernization" element={<ApplicationModernization />} />
          <Route path="/database-modernization" element={<DatabaseModernization />} />
          <Route path="/infrastructure-modernization" element={<InfraStructureModernization />} />
          <Route path="/cybersecurity" element={<CyberSecurity />} />
          <Route path="/site-reliabilities" element={<SiteReliabilityServices />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/staffing-and-consulting" element={<StaffingandITConsulting />} />
          <Route path="/robotic-process-automation" element={<RoboticProcessAutomation />} />
          <Route path="/data-and-ai" element={<DataandAI />} />
          <Route path="/cloud-and-devops" element={<CloudandDevOps />} />
          <Route path="/snowflake-and-informatica" element={<SnowflakeandInformatica />} />
          <Route path="/servicenow" element={<ServiceNow />} />
          <Route path="/epr" element={<EPR />} />
          <Route path="/crm" element={<CRM />} />
          <Route path="/adobe-experience-manager" element={<AdoveExperienceManager />} />
          <Route path="/optimizely" element={<Optimizely />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
